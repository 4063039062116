import React from "react";
import { useRoutes } from "react-router-dom";
import { MainLayout } from "../components/layout";
import { ProtectedRoute } from "../components/other";
import {
  Calendar,
  Dashboard,
  Payments,
  Registration,
  Reports,
  Subjects,
  DataImports,
  Services,
  Login,
  Users,
  NotFound,
  Reviews,
  Requests,
  RequestsConfig,
  Settings,
  Sponsorships,
  Required,
  LoginAdm,
  FakeLogin,
  ErrorPayment,
  PaymentReceipt,
  ChargesSettings,
} from "../pages";
import AdminWithdraw from "src/pages/admin-pages/adminWithdraw/admin-withdraw";
import { EmailTemplates } from "src/pages/admin-pages/emailTemplates";
import { Sponsors } from "src/pages/admin-pages/sponsors";
import { FakeSelection } from "src/pages/admin-pages/fakeSelection";
import SponsorshipsChanges from "src/pages/admin-pages/sponsorships/sponsorshipsChanges";

const navigation = [
  {
    path: "/",
    element: (
      <ProtectedRoute
        allowedRoles={[
          "administrador(a) del sistema",
          "estudiante",
          "cajero",
          "perfil financiero",
          "vicerrectoria",
          "matricula administrativa",
          "retiro administrativo",
        ]}
      />
    ),
    children: [
      {
        path: "",
        element: <MainLayout />,
        children: [
          {
            path: "",
            element: <Dashboard />,
          },
          {
            path: "",
            element: <ProtectedRoute allowedRoles={["estudiante", "matricula administrativa"]} />,
            children: [
              {
                path: "/subjects-list",
                element: <Subjects />,
              },
              {
                path: "/subjects-registration/:program",
                element: <Registration />,
              },
              {
                path: "/requests",
                element: <Requests />,
              },
              {
                path: "/cart/:previous",
                element: <Payments />,
              },
              {
                path: "/calendar",
                element: <Calendar />,
              },
              {
                path: "/reports",
                element: <Reports />,
              },
              { title: "Payment Error", path: "payment-error/:error", element: <ErrorPayment /> },
            ],
          },
          {
            path: "",
            element: <ProtectedRoute allowedRoles={["vicerrectoria"]} />,
            children: [
              {
                path: "/admin/data-imports",
                element: <DataImports />,
              },
            ],
          },
          {
            path: "",
            element: <ProtectedRoute allowedRoles={["administrador(a) del sistema"]} />,
            children: [
              {
                path: "/admin/requests",
                element: <RequestsConfig />,
              },
              {
                path: "/admin/services",
                element: <Services />,
              },
              {
                path: "/admin/users",
                element: <Users />,
              },
              {
                path: "/admin/sponsors",
                element: <Sponsors />,
              },
              {
                path: "/admin/email-templates",
                element: <EmailTemplates />,
              },
              {
                path: "/admin/settings",
                element: <Settings />,
              },
              {
                path: "/admin/user-impersonation",
                element: <FakeLogin />,
              },
            ],
          },
          {
            path: "",
            element: <ProtectedRoute allowedRoles={["matricula administrativa"]} />,
            children: [
              {
                path: "/admin/user-selection",
                element: <FakeSelection />,
              },
            ],
          },
          {
            path: "",
            element: (
              <ProtectedRoute
                allowedRoles={["retiro administrativo", "administrador(a) del sistema"]}
              />
            ),
            children: [
              {
                path: "/admin/subjects-withdraw",
                element: <AdminWithdraw />,
              },
            ],
          },
          {
            path: "",
            element: <ProtectedRoute allowedRoles={["cajero"]} />,
            children: [
              {
                path: "/admin/charges",
                element: <ChargesSettings />,
              },
            ],
          },
          {
            path: "",
            element: <ProtectedRoute allowedRoles={["cajero", "estudiante"]} />,
            children: [
              {
                path: "/last-receipt/:invoiceId/:peopleId",
                element: <PaymentReceipt />,
              },
            ],
          },
          {
            path: "",
            element: <ProtectedRoute allowedRoles={["perfil financiero"]} />,
            children: [
              {
                path: "/admin/reviews",
                element: <Reviews />,
              },
              {
                path: "/admin/sponsorships",
                element: <Sponsorships />,
              },
              {
                path: "/admin/sponsorships/changes",
                element: <SponsorshipsChanges />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/loginAdm",
    element: <LoginAdm />,
  },
  { title: "Required", path: "required", element: <Required /> },
  { title: "Not Found", path: "*", element: <NotFound /> },
];

export const RouteElements: React.FC = (): React.ReactElement | null => {
  const element = useRoutes(navigation);
  return element;
};
